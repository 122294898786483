import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArrowRectangle from "components/ArrowRectangle/ArrowRectangle";
import { fontstack } from "utils/fontstack";
import VideoTag from "components/VideoTag/VideoTag";
import Video from "modules/Video/Video";
import { media } from "utils/mediaQuery";
import { gsap } from "gsap";


const Stage = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 115%;
  overflow: hidden;
  border-radius: 5px;
  transition: all .4s ease;
  z-index: 1;
  border: 1px solid transparent;
  filter: drop-shadow(0px 0px 0px transparent);

  ${media.medium`
    padding-bottom: ${(props) => props.square ? "80%" : "52.6%"};
  `}
`;

const ImageInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform .4s ease;
`;

const CoverImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  object-fit: cover;
  transition: opacity .4s ease;

`

const Tags = styled.div`
  position: absolute;
  bottom: 14px;
  width: 100%;
  padding: 0 14px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: 5px 10px 0 0;
  }
  
`

const TagWrapper = styled.div`
  transform: translate(0,0);
  opacity: 1;

  ${media.medium`
    transform: translate(0,50px);
    opacity: 0;
  `}
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.medium`
    justify-content: ${(props) => props.spaceBetween ? "space-between" : "flex-start"};
    flex-direction: row;
  `}
`

const ArrowWrapper = styled.div`
  position: relative;
  width: 3vw;
  display: none;

  ${media.medium` 
    display: block;
  `}
`

const Title = styled.h2`
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  margin: 0;
  transition: color .4s ease;

  ${media.medium` 
    font-size: var(--font-size-md-small);
    margin: 0 0 12px;
    color: #434343;
  `}
`

const SubTitle = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: #807F7F;
  margin: 0 0 9px;
  transition: opacity .2s ease;
  opacity: 1;

  ${media.medium` 
    font-size: var(--font-size-md-small);
    margin: 0 0 12px 10px;
    opacity: 0;
  `}
`

const Wrapper = styled.div`
  
  &:hover ${Title} {
    color: white;
  }

  &:hover ${SubTitle} {
    opacity: ${(props) => props.hasButton ? 0 : 1};
  }

  &:hover ${ImageInner} {
    transform: scale(1.02);
  }

  &:hover ${CoverImage} {
    opacity: 0;
  }

  &:hover ${ImageWrapper} {
    filter: drop-shadow(0px 0px 8px ${(props) => props.accent ? props.accent : "#FFFFFF"});
    border: 1px solid white;
  }

  ${media.medium` 
    & ${SubTitle} {
      display: ${(props) => props.hasButton ? "none" : "block"};
    }
  `}
`;

const DesktopVideo = styled(Video)`
  display: none;
  ${media.medium`
    display: block;
  `}
`

const MobileVideo = styled(Video)`
  ${media.medium`
    display: none;
  `}
`

const WorkVideoSummary = ({node, hasButton, square, ...rest}) => {
  const {
    title,
    subtitle,
    tags,
    category,
    thumbnail,
    accentColor
  } = node;

  const [ Active, setActive ] = React.useState(false);
  const TagArray = React.useRef([]);
  const tl = React.useRef();
  const videoRef = React.useRef();
  const isPlaying = React.useRef();
  const videoMobileRef = React.useRef();

  React.useEffect(() => {
    tl.current = gsap.timeline({paused: true});
    tl.current.to(TagArray.current, { 
      opacity: 1,
      y: 0,
      stagger:0.08, 
      ease: "back.inOut(0.2)",
    })
  }, [])

  const onEnter = () => {
    if(window.matchMedia("(min-width: 900px)").matches) { 
      setActive(true)
      if(tl.current) {
        tl.current.play();
      }

      if(videoRef.current) {
        const playpromise = videoRef.current.play();

        playpromise.then(() => {
        }).catch((error) => {
          console.log(error)
        })
      }
    }

  }

  const onLeave = () => {
    if(window.matchMedia("(min-width: 900px)").matches) { 
      setActive(false)
      if(tl.current) {
       tl.current.reverse();
      }
  
      if(videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }

    }
  }
  
  const imageObject = thumbnail?.image ? getImage(thumbnail?.image?.asset) : null;

  return (
    <Wrapper onMouseEnter={onEnter} onMouseLeave={onLeave} accent={accentColor} hasButton={hasButton} {...rest}>
      <Header spaceBetween={hasButton}>
        <Title>{title}</Title>
        { subtitle && <SubTitle>{subtitle}</SubTitle>}
        { hasButton && <ArrowWrapper> 
          <ArrowRectangle active={Active}/>
        </ArrowWrapper> }
      </Header>
      <Stage>
        <ImageWrapper square={square}>
        { imageObject && <CoverImage  
          image={imageObject}
          placeholder="blurred" 
          alt={`${thumbnail.image.alt}`}
          layout="fullWidth"/>}
          <ImageInner>
            { thumbnail && thumbnail.video ? <DesktopVideo ref={videoRef} src={thumbnail.video} playsInline defaultMuted muted loop cover={square}/> : null }
            { thumbnail && thumbnail.mobileVideo ? <MobileVideo src={`${thumbnail.mobileVideo}#t=0.001`} playsInline defaultMuted muted loop/> : null }
          </ImageInner>
          <Tags>{category ? <TagWrapper ref={(ref) => TagArray.current[0] = ref}><VideoTag>{category.label}</VideoTag></TagWrapper> : null} { tags && tags.map((tag, i) => <TagWrapper key={`${title}-tag-${i}`}ref={(ref) => { const count = category ? i+1 : i; TagArray.current[count] = ref; }}><VideoTag>{tag.label}</VideoTag></TagWrapper>)}</Tags>
        </ImageWrapper>
      </Stage>
    </Wrapper>
  ) 
}

export default WorkVideoSummary;
